import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Prime from "../views/primedenaissance.vue";
import Confirmation from "../views/Confirmation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/formulaire",
    name: "BirthAllowance",
    component: Prime
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
