import axios from "axios";
import environmentMixin from "./environmentMixin.js";

export default {
    mixins: [environmentMixin],
    data() {
        return {
            countries: null
        }
    },
    async created() {
        let response = await axios.get(this.urlBackend + '/allCountriesValidNow');
        this.countries = response.data;
    },
    methods: {
        findLabelByNisCode(inNisCode) {
            let res = '';
            this.countries.forEach(country => {
                if (res === '') {
                    res = (country["nisCode"] === inNisCode) ? country["nameFrench"] : '';
                }
            });
            if (res === '') {
                res = 'indéfini';
            }
            return res;
        }
    }
}