import Vue from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vueCountryRegionSelect from 'vue-country-region-select';

Vue.use(VueFormWizard);
Vue.use(Vuelidate);

Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);

Vue.use(vueCountryRegionSelect)