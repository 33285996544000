
export default {
    methods: {
        formatNiss(value) {
            value = value.replace(/[^0-9]/g, '');
            var niss = "";
            niss += value.charAt(0);
            niss += value.charAt(1);
            if (niss.length == 2) {
                niss += ".";
            }
            niss += value.charAt(2);
            niss += value.charAt(3);
            if (niss.length == 5) {
                niss += ".";
            }
            niss += value.charAt(4);
            niss += value.charAt(5);
            if (niss.length == 8) {
                niss += "-";
            }
            niss += value.charAt(6);
            niss += value.charAt(7);
            niss += value.charAt(8);
            if (niss.length == 12) {
                niss += ".";
            }
            niss += value.charAt(9);
            niss += value.charAt(10);
            return niss;
        }
    }
}
