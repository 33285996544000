
export default {
    methods: {
        formatBelgianIban(value) {
          value = value.replace(/ /g,'');
          if (value === undefined || value === null) {
            return value;
          }
          var iban = "";
          iban += value.charAt(0);
          iban += value.charAt(1);
          iban += value.charAt(2);
          iban += value.charAt(3);
          if (iban.length == 4) {
            iban += " ";
          }
          iban += value.charAt(4);
          iban += value.charAt(5);
          iban += value.charAt(6);
          iban += value.charAt(7);
          if (iban.length == 9) {
            iban += " ";
          }
          iban += value.charAt(8);
          iban += value.charAt(9);
          iban += value.charAt(10);
          iban += value.charAt(11);
          if (iban.length == 14) {
            iban += " ";
          }
          iban += value.charAt(12);
          iban += value.charAt(13);
          iban += value.charAt(14);
          iban += value.charAt(15);
          return iban.toUpperCase();
        }
    }
}
