<template>
  <div class="d-flex flex-column parent img">
    <div><Header /></div>
    <div id="app">
      <router-view />
    </div>
    <div class="mt-auto"><Footer /></div>
    <div id="cookiesPanel"><Cookies /></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Cookies from "@/components/Cookies.vue";
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
    Footer,
    Cookies
  }
};
</script>

<style lang="scss" src="./style/styles.scss"></style>
