import Vue from 'vue'
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(require('vue-cookies'))
Vue.use(VueCookies, { expire: '7d'})

export default {
  data() {
    return {
      primedenaissanceFamiwalCookies: {
        mandatoryCookies: true,
        functionalCookies: true,
        statisticsCookies: false,
        showCookiesPanel: true,
        showDetailedCookiesPanel: false
      }
    }
  },
  methods: {
    acceptAllCookies() {
      this.primedenaissanceFamiwalCookies.statisticsCookies = true;
      this.confirmCustomCookies();
    },
    acceptPartiallyCookies() {
      this.primedenaissanceFamiwalCookies.statisticsCookies = false;
      this.confirmCustomCookies();
    },
    confirmCustomCookies() {
      this.primedenaissanceFamiwalCookies.showCookiesPanel = false;
      this.primedenaissanceFamiwalCookies.showDetailedCookiesPanel = false;
      this.$cookies.set("Orejime", this.primedenaissanceFamiwalCookies);
    },
    triggerStatisticsCookies() {
      this.primedenaissanceFamiwalCookies.statisticsCookies = !this.primedenaissanceFamiwalCookies.statisticsCookies;
    }
  },
  created() {
    if(this.$cookies.isKey("Orejime")) {
      this.primedenaissanceFamiwalCookies = this.$cookies.get("Orejime"); 
    }
  }
};