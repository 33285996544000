<template>
    <div>
      <b-container>
        <b-col md="8" offset-md="2" style="padding-top: 10%;">
          <div class="text-left">
            <p>Votre demande d'allocation de naissance est bien enregistrée auprès 
            de FAMIWAL, la caisse publique wallonne d'allocations familiales.</p>
          </div>
          <b-button class="my-4 btnHome pa-2" href="https://www.famiwal.be/accedez-aux-themes/naissance-et-adoption/allocation-prime-de-naissance">
          <b-icon icon="check-circle"></b-icon> Bienvenue chez FAMIWAL </b-button>
        </b-col>  
      </b-container>
    </div>
</template>

<script>
export default {
    name:"Confirmation"
}
</script>

<style lang="scss" src="../style/styles.scss" scoped></style>