import { validationMixin } from "vuelidate";
import { required, maxLength, helpers, requiredIf, minLength, between } from "vuelidate/lib/validators";
import countriesMixin from "../mixins/countriesMixin.js";
import nissFormatterMixin from "../mixins/nissFormatterMixin.js";
import environmentMixin from "../mixins/environmentMixin.js";
import axios from "axios";

function validateBirthdate(bd) {
  let birthdate = new Date(bd);
  let today = new Date();
  return !(birthdate.valueOf() > today.setFullYear(today.getFullYear() - 5)) && !(birthdate.valueOf() < today.setFullYear(1900)); // Mother birthdate after today 
};

const validBirthdate = (bd) => validateBirthdate(bd);

const regex_niss = helpers.regex(
  "regex_niss",
  /^[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{3}[.\- ]{0,1}[0-9]{2}$/
);

export default {
  name: "MomData",
  mixins: [validationMixin, countriesMixin, nissFormatterMixin, environmentMixin],
  data() {
    return {
      momDataForm: {
        firstname: "",
        name: "",
        niss: "",
        birthdate: "",
        belgianZipCode: "",
        foreignStreet: "",
        foreignStreetNumber: "",
        foreignZipCode: "",
        foreignCity: "",
        foreignCountry: null,
        firstChild: null
      },
      etranger: false,
      pasDeNiss: false
    };
  },
  methods: {
    validateState(objet) {
      const { $dirty, $error } = this.$v.momDataForm[objet];
      return $dirty ? !$error : null;
    },
    formatMotherNiss() {
      this.momDataForm.niss = this.formatNiss(this.momDataForm.niss);
    },
    async validNiss(niss) {
      if (Boolean(this.pasDeNiss) === true) {
        return true;
      }
      let isValid = await axios.get(this.urlBackend + '/validateNiss/' + niss).then(response => {
        return response.data === true;
      }).catch(error => {
        console.error(error);
        return false;
      });
      return isValid;
    }
  },
  watch: {
    etranger() {
      this.momDataForm.belgianZipCode = "";
      this.momDataForm.foreignStreet = "";
      this.momDataForm.foreignStreetNumber = "";
      this.momDataForm.foreignZipCode = "";
      this.momDataForm.foreignCity = "";
      this.momDataForm.foreignCountry = null;
    },
    pasDeNiss() {
      this.momDataForm.niss = "";
      this.momDataForm.birthdate = "";
    }
  },
  validations() {
    return {
      momDataForm: {
        firstname: { required, minLength: minLength(2), maxLength: maxLength(255) },
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        niss: { required: this.validNiss, regex_niss },
        birthdate: { required: requiredIf(() => this.pasDeNiss), validBirthdate },
        belgianZipCode: { required: requiredIf(() => !this.etranger), between: between(1000, 9999) },
        firstChild: { required },
        foreignStreet: { required: requiredIf(() => this.etranger), minLength: minLength(1), maxLength: maxLength(255) },
        foreignStreetNumber: { required: requiredIf(() => this.etranger), minLength: minLength(1), maxLength: maxLength(10) },
        foreignZipCode: { required: requiredIf(() => this.etranger), minLength: minLength(1), maxLength: maxLength(32) },
        foreignCity: { required: requiredIf(() => this.etranger), minLength: minLength(1), maxLength: maxLength(255) },
        foreignCountry: { required: requiredIf(() => this.etranger), minLength: minLength(5), maxLength: maxLength(5) },
      }
    };
  }
};