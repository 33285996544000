import MomData from "@/components/views/MomData.vue";
import AccountNumber from "@/components/views/AccountNumber.vue";
import DateOfBirth from "@/components/views/DateOfBirth.vue";
import WorkingAbroad from "@/components/views/WorkingAbroad.vue";
import ContactInformation from "@/components/views/ContactInformation.vue";
import Overview from "@/components/views/Overview.vue";
import Vue from "vue";
import axios from "axios";
import environmentMixin from "./mixins/environmentMixin.js";
import BankAccountEnum from "./enums/bankAccountEnum.js";

export default {
  components: {
    MomData,
    AccountNumber,
    DateOfBirth,
    WorkingAbroad,
    ContactInformation,
    Overview
  },
  mixins: [environmentMixin],
  data() {
    return {
      formulaire: {
        // momData
        lastname: "",
        firstname: "",
        niss: "",
        motherBirthdate: "",
        belgianZipCode: "",
        foreignStreet: "",
        foreignStreetNumber: "",
        foreignZipCode: "",
        foreignCity: "",
        foreignCountry: "",
        firstChild: "",
        // accountNumber
        belgianAccount: "",
        foreignAccount: "",
        ribStatus: "",
        ribName: "",
        ribSize: "",
        ribType: "",
        ribData: null,
        // dateOfBirth
        childPlannedBirthdate: "",
        isMultiplePregnancy: "",
        pregnancyAttestationStatus: "",
        pregnancyAttestationName: "",
        pregnancyAttestationSize: "",
        pregnancyAttestationType: "",
        pregnancyAttestationData: null,
        // workingAbroad
        isWorkingInForeignCountry: "",
        isWorkingInInternationalOrganisation: "",
        hasSocialBenefit: "",
        foreignCountryHeadOffice: "",
        foreignCountrySocialBenefit: "",
        // contactInfo
        email: "",
        phone: "",
        howDidYouFindUs: "",
        // conditions
        rgpd: false,
        famiwalAsCaf: false
      },
      submitClicked: false
    };
  },
  methods: {
    disableSubmit() {
      this.submitClicked = true;
    },
    enableSubmit() {
      this.submitClicked = false;
    },
    validateMomData() {
      this.enableSubmit();
      this.$refs.momData.$v.momDataForm.$touch();
      if (this.$refs.momData.$v.momDataForm.$invalid === true) {
        return false;
      } else return true;
    },
    validateAccountNumber() {
      this.enableSubmit();
      this.$refs.accountNumber.$v.accountForm.$touch();
      if (this.$refs.accountNumber.$v.accountForm.$invalid === true) {
        return false;
      } else return true;
    },
    validateDateOfBirth() {
      this.enableSubmit();
      this.$refs.dateOfBirth.$v.dateBirthForm.$touch();
      if (this.$refs.dateOfBirth.$v.dateBirthForm.$invalid === true) {
        return false;
      } else return true;
    },
    validateWorkingAbroad() {
      this.enableSubmit();
      this.$refs.workingAbroad.$v.workingAbroadForm.$touch();
      if (this.$refs.workingAbroad.$v.workingAbroadForm.$invalid === true) {
        return false;
      } else return true;
    },
    validateContactInformation() {
      this.enableSubmit();
      this.$refs.contactInfo.$v.contactInfoForm.$touch();
      if (this.$refs.contactInfo.$v.contactInfoForm.$invalid === true) {
        return false;
      } else return true;
    },
    onComplete() {
      this.disableSubmit();
      var bodyFormData = new FormData();
      bodyFormData.append("attestation", this.formulaire.pregnancyAttestationData);
      bodyFormData.append("rib", this.formulaire.ribData);
      bodyFormData.append("formulaire", new Blob([JSON.stringify(this.formulaire)], {
        type: "application/json"
      }));

      axios.post(this.urlBackend + "/birthAllowanceForm", bodyFormData,
        {
          headers:  
            { "Content-Type": "multipart/form-data" }
        }
      )
        .then(response => {
          console.log("POST to birthAllowance successfull!", response);
          this.$router.push('/confirmation');
          return true;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("POST to birthAllowance error !", error);
          this.$refs.overview.errorPost = "Certaines données du formulaire sont incorrectes : " + error.response.data;
          window.scrollTo(0, 0); // Go to top of the page
          return false;
        });
    },
    createOverview() {
      this.$refs.overview.errorPost = '';
      //MomData
      Vue.set(this.formulaire, "lastname", this.$refs.momData.$v.momDataForm.name.$model);
      Vue.set(this.formulaire, "firstname", this.$refs.momData.$v.momDataForm.firstname.$model);
      if (String(this.$refs.momData.momDataForm.birthdate) !== '') {
        let birthdate = this.formatDate(this.$refs.momData.momDataForm.birthdate);
        Vue.set(this.formulaire, "motherBirthdate", birthdate);
        Vue.set(this.formulaire, "niss", "");
      } else {
        Vue.set(this.formulaire, "motherBirthdate", "");
        Vue.set(this.formulaire, "niss", this.$refs.momData.$v.momDataForm.niss.$model);
      }
      if (String(this.$refs.momData.momDataForm.foreignZipCode) === '' || null === this.$refs.momData.momDataForm.foreignZipCode
        || undefined === this.$refs.momData.momDataForm.foreignZipCode) {
        Vue.set(this.formulaire, "belgianZipCode", this.$refs.momData.momDataForm.belgianZipCode);
        Vue.set(this.formulaire, "foreignStreet", "");
        Vue.set(this.formulaire, "foreignStreetNumber", "");
        Vue.set(this.formulaire, "foreignZipCode", "");
        Vue.set(this.formulaire, "foreignCity", "");
        Vue.set(this.formulaire, "foreignCountry", "");
      } else {
        Vue.set(this.formulaire, "belgianZipCode", "");
        Vue.set(this.formulaire, "foreignStreet", this.$refs.momData.momDataForm.foreignStreet);
        Vue.set(this.formulaire, "foreignStreetNumber", this.$refs.momData.momDataForm.foreignStreetNumber);
        Vue.set(this.formulaire, "foreignZipCode", this.$refs.momData.momDataForm.foreignZipCode);
        Vue.set(this.formulaire, "foreignCity", this.$refs.momData.momDataForm.foreignCity);
        Vue.set(this.formulaire, "foreignCountry", this.$refs.momData.momDataForm.foreignCountry);
      }
      Vue.set(this.formulaire, "firstChild", this.$refs.momData.$v.momDataForm.firstChild.$model);
      // AccountNumber
      if (this.$refs.accountNumber.accountForm.accountType === BankAccountEnum.BELGIAN) {
        Vue.set(this.formulaire, "belgianAccount", this.$refs.accountNumber.accountForm.belgianAccount);
        Vue.set(this.formulaire, "foreignAccount", "");
        Vue.set(this.formulaire, "ribName", "");
        Vue.set(this.formulaire, "ribSize", "");
        Vue.set(this.formulaire, "ribType", "");
        Vue.set(this.formulaire, "ribData", "");
        Vue.set(this.formulaire, "ribStatus", "Relevé d'identité bancaire rendue");
      } else if (this.$refs.accountNumber.accountForm.accountType === BankAccountEnum.FOREIGN) {
        Vue.set(this.formulaire, "belgianAccount", "");
        Vue.set(this.formulaire, "foreignAccount", this.$refs.accountNumber.accountForm.foreignAccount);
        Vue.set(this.formulaire, "ribName", this.$refs.accountNumber.accountForm.rib.name);
        Vue.set(this.formulaire, "ribSize", this.$refs.accountNumber.accountForm.rib.size);
        Vue.set(this.formulaire, "ribType", this.$refs.accountNumber.accountForm.rib.type);
        Vue.set(this.formulaire, "ribData", this.$refs.accountNumber.accountForm.rib);
        Vue.set(this.formulaire, "ribStatus", "Relevé d'identité bancaire rendue");
      } else {
        Vue.set(this.formulaire, "belgianAccount", "");
        Vue.set(this.formulaire, "foreignAccount", "");
        Vue.set(this.formulaire, "ribName", "");
        Vue.set(this.formulaire, "ribSize", "");
        Vue.set(this.formulaire, "ribType", "");
        Vue.set(this.formulaire, "ribData", "");
        Vue.set(this.formulaire, "ribStatus", "Relevé d'identité bancaire non rendue");
      }
      // DateOfBirth
      var childPlannedBirthdate = this.formatDate(this.$refs.dateOfBirth.dateBirthForm.childPlannedBirthdate);
      Vue.set(this.formulaire, "childPlannedBirthdate", childPlannedBirthdate);
      Vue.set(this.formulaire, "isMultiplePregnancy", this.$refs.dateOfBirth.dateBirthForm.isMultiplePregnancy);
      if (this.$refs.dateOfBirth.dateBirthForm.pregnancyAttestation !== null && this.$refs.dateOfBirth.dateBirthForm.pregnancyAttestation !== undefined) {
        Vue.set(this.formulaire, "pregnancyAttestationStatus", "Rendue");
        Vue.set(this.formulaire, "pregnancyAttestationName", this.$refs.dateOfBirth.dateBirthForm.filename);
        Vue.set(this.formulaire, "pregnancyAttestationSize", this.$refs.dateOfBirth.dateBirthForm.pregnancyAttestation.size);
        Vue.set(this.formulaire, "pregnancyAttestationType", this.$refs.dateOfBirth.dateBirthForm.pregnancyAttestation.type);
        Vue.set(this.formulaire, "pregnancyAttestationData", this.$refs.dateOfBirth.dateBirthForm.pregnancyAttestation);
      } else {
        Vue.set(this.formulaire, "pregnancyAttestationStatus", "Non rendue");
        Vue.set(this.formulaire, "pregnancyAttestationName", "");
        Vue.set(this.formulaire, "pregnancyAttestationSize", "");
        Vue.set(this.formulaire, "pregnancyAttestationType", "");
        Vue.set(this.formulaire, "pregnancyAttestationData", "");
      }
      // WorkingAbroad
      Vue.set(this.formulaire, "isWorkingInForeignCountry", this.$refs.workingAbroad.workingAbroadForm.isWorkingInForeignCountry);
      if (this.$refs.workingAbroad.workingAbroadForm.foreignCountryHeadOffice !== null && this.$refs.workingAbroad.workingAbroadForm.foreignCountryHeadOffice !== undefined) {
        Vue.set(this.formulaire, "foreignCountryHeadOffice", this.$refs.workingAbroad.workingAbroadForm.foreignCountryHeadOffice);
      } else {
        Vue.set(this.formulaire, "foreignCountryHeadOffice", "");
      }
      Vue.set(this.formulaire, "isWorkingInInternationalOrganisation", this.$refs.workingAbroad.workingAbroadForm.isWorkingInInternationalOrganisation);
      Vue.set(this.formulaire, "hasSocialBenefit", this.$refs.workingAbroad.workingAbroadForm.hasSocialBenefit);
      if (this.$refs.workingAbroad.workingAbroadForm.foreignCountrySocialBenefit !== null && this.$refs.workingAbroad.workingAbroadForm.foreignCountrySocialBenefit !== undefined) {
        Vue.set(this.formulaire, "foreignCountrySocialBenefit", this.$refs.workingAbroad.workingAbroadForm.foreignCountrySocialBenefit);
      } else {
        Vue.set(this.formulaire, "foreignCountrySocialBenefit", "");
      }
      // contactInfo
      Vue.set(this.formulaire, "email", this.$refs.contactInfo.$v.contactInfoForm.email.$model);
      Vue.set(this.formulaire, "phone", this.$refs.contactInfo.$v.contactInfoForm.phone.$model);
      Vue.set(this.formulaire, "rgpd", this.$refs.contactInfo.contactInfoForm.hasAcceptedRgpd);
      Vue.set(this.formulaire, "famiwalAsCaf", this.$refs.contactInfo.contactInfoForm.hasAcceptedFamiwalAsCaf);
      Vue.set(this.formulaire, "howDidYouFindUs", this.$refs.contactInfo.contactInfoForm.howDidYouFindUs);
      Vue.set(this.formulaire, "howDidYouFindUsText", this.$refs.contactInfo.contactInfoForm.howDidYouFindUsText);
    },
    formatDate(value) {
      var format = "";
      format += value.charAt(8);
      format += value.charAt(9);
      format += "/";
      format += value.charAt(5);
      format += value.charAt(6);
      format += "/";
      format += value.charAt(0);
      format += value.charAt(1);
      format += value.charAt(2);
      format += value.charAt(3);
      return format;
    }
  }
};