import { validationMixin } from "vuelidate";
import countriesMixin from "../mixins/countriesMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, countriesMixin],
  name: "WorkingAbroad",
  data() {
    return {
      workingAbroadForm: {
        isWorkingInForeignCountry: null,
        isWorkingInInternationalOrganisation: null,
        hasSocialBenefit: null,
        foreignCountryHeadOffice: null,
        foreignCountrySocialBenefit: null
      }
    };
  },
  validations() {
    return {
      workingAbroadForm: {
        isWorkingInForeignCountry: { required },
        isWorkingInInternationalOrganisation: { required },
        hasSocialBenefit: { required },
        foreignCountryHeadOffice: { required: requiredIf(() => this.workingAbroadForm.isWorkingInForeignCountry === true) },
        foreignCountrySocialBenefit: { required: requiredIf(() => this.workingAbroadForm.hasSocialBenefit === true) }
      }
    };
  },
  methods: {
    validateState(objet) {
      const { $dirty, $error } = this.$v.workingAbroadForm[objet];
      return $dirty ? !$error : null;
    }
  }
};