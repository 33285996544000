import { validationMixin } from "vuelidate";
import countriesMixin from "../mixins/countriesMixin.js";

export default {
  mixins: [validationMixin, countriesMixin],
  name: "Overview",
  props: ["recap"],
  data() {
    return {
      overview: {
      },
      errorPost: ''
    };
  },
  methods: {
    validateState(objet) {
      const { $dirty, $error } = this.$v.overview[objet];
      return $dirty ? !$error : null;
    },
    howDidYouFindUsPrettyPrint(reason, reasonFreeText) {
      if (reason == 'other') {
        return "Autre (" + reasonFreeText + ")";
      } else if (reason == 'work') {
        return "Employeur";
      } else if (reason == 'family') {
        return "Bouche à oreille (exemple : parent, ami...)";
      } else if (reason == 'event') {
        return "Salon ou évènement";
      } else if (reason == 'institution') {
        return "Institution (exemple : CPAS, administration communale, maternité...)";
      } else if (reason == 'search_engine') {
        return "Moteur de recherche Internet (exemple : Google, Bing)";
      } else if (reason == 'social') {
        return "Réseaux sociaux (exemple : Facebook, Instagram, Linkedin, Youtube)";
      } else if (reason == 'advertisement') {
        return "Publicité (exemple : presse, panneau en rue)";
      }
      return reason;
    }
  }
};