<template>
  <header class="my-3">
    <a href="/">
      <b-img
        src="https://resourceserver.prd.pub.famiwal.be/img/logo_famiwal.png"
        fluid
        alt="Logo de Famiwal"
        center
        width="300%"
      ></b-img>
    </a>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped></style>
