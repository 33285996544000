
export default {
  data() {
    return {
      urlBackend: '',
      environment: ''
    }
  },
  created() {
    let currentUrl = window.location.href;
    let environment = "";
    let urlBackend = "";
    if (currentUrl.includes('localhost')) {
      environment = "dev";
      urlBackend = "http://localhost:8081";
    }
    if (currentUrl.includes('primedenaissance.tst.paas.famiwal.be')) {
      environment = "test";
      urlBackend = "http://primedenaissancebackendose.tst.paas.famiwal.be";
    }
    if (currentUrl.includes('primedenaissance.int.paas.famiwal.be')) {
      environment = "int";
      urlBackend = "http://primedenaissancebackendose.int.paas.famiwal.be";
    }
    if (currentUrl.includes('primedenaissance.acc.paas.famiwal.be')) {
      environment = "acc";
      urlBackend = "http://primedenaissancebackendose.acc.paas.famiwal.be";
    }
    if (currentUrl.includes('primedenaissance.prd.pub.famiwal.be') ||
        currentUrl.includes('primedenaissance.prd.paas.famiwal.be') ||
        currentUrl.includes('primedenaissance.wallonie.be')) {
      environment = "prod";
      urlBackend = "https://primedenaissancebackendose.prd.pub.famiwal.be";
    }
    if(urlBackend === "") {
      environment = "prod";
      urlBackend = "https://primedenaissancebackendose.prd.pub.famiwal.be"
    }
    this.urlBackend = urlBackend;
    this.environment = environment;
  }
}
