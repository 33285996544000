import { validationMixin } from "vuelidate";
import { required, requiredIf, email, helpers, sameAs } from "vuelidate/lib/validators";

const international_phone = helpers.regex(
  "international_phone",
  /^[0-9./+-]*$/
);

export default {
  mixins: [validationMixin],
  name: "ContactInformation",
  data() {
    return {
      contactInfoForm: {
        email: "",
        phone: "",
        howDidYouFindUs: "",
        howDidYouFindUsText: "",
        hasAcceptedRgpd: false,
        hasAcceptedFamiwalAsCaf: false
      },
      enableOtherText: false
    };
  },
  validations() {
    return {
      contactInfoForm: {
        email: { required, email },
        phone: { required, international_phone },
        hasAcceptedRgpd: { sameAs: sameAs(() => true) },
        hasAcceptedFamiwalAsCaf: { sameAs: sameAs(() => true) },
        howDidYouFindUsText: { required: requiredIf(() => this.enableOtherText === true) }
      }
    };
  },
  methods: {
    validateState(objet) {
      const { $dirty, $error } = this.$v.contactInfoForm[objet];
      return $dirty ? !$error : null;
    },
    otherSelected() {
      this.enableOtherText = true;
    },
    otherNotSelected() {
      this.enableOtherText = false;
      this.contactInfoForm.howDidYouFindUsText = "";
    },
    maxLength255(v) {
      return String(v).substring(0, 255);
    }
  }
};