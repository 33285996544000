import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { requiredUnless } from "vuelidate/lib/validators";
import resizeImageMixin from "../mixins/resizeImageMixin.js";

function validateBirthdate(bd) {
  let birthdate = new Date(bd);
  let minDate = new Date();
  let maxDate = new Date();
  return (birthdate.valueOf() > minDate.setFullYear(1900) && birthdate.valueOf() < maxDate.setMonth(maxDate.getMonth() + 10));
};

const validBirthdate = (bd) => validateBirthdate(bd);

export default {
  mixins: [validationMixin, resizeImageMixin],
  name: "DateOfBirth",
  data() {
    return {
      dateBirthForm: {
        childPlannedBirthdate: "",
        isMultiplePregnancy: null,
        pregnancyAttestation: null,
        filename: ''
      },
      hasNotYetPregnancyAttestation: false,
      couldntDownloadPregnancyAttestation: false
    };
  },
  validations() {
    return {
      dateBirthForm: {
        childPlannedBirthdate: { required, validBirthdate },
        isMultiplePregnancy: { required },
        pregnancyAttestation: { required: requiredUnless(() => this.hasNotYetPregnancyAttestation || this.couldntDownloadPregnancyAttestation) }
      }
    };
  },
  watch: {
    couldntDownloadPregnancyAttestation(isNoDownloadSelected) {
      if (isNoDownloadSelected) {
        this.dateBirthForm.pregnancyAttestation = null;
      }
    },
    hasNotYetPregnancyAttestation(isNoAttestationSelected) {
      if (isNoAttestationSelected) {
        this.dateBirthForm.pregnancyAttestation = null;
      }
    }
  },
  methods: {
    validateState(objet) {
      const { $dirty, $error } = this.$v.dateBirthForm[objet];
      return $dirty ? !$error : null;
    },
    onFileChange(e) {
      this.dateBirthForm.pregnancyAttestation = e.target.files[0];
      this.dateBirthForm.filename = this.dateBirthForm.pregnancyAttestation.name;
      if (this.dateBirthForm.pregnancyAttestation !== null && this.dateBirthForm.pregnancyAttestation !== undefined &&
        (this.dateBirthForm.pregnancyAttestation.type === "image/jpeg" || this.dateBirthForm.pregnancyAttestation.type === "image/png"
          || this.dateBirthForm.pregnancyAttestation.type === "image/gif")) {
        let response = this.resizeImage({
          file: this.dateBirthForm.pregnancyAttestation,
          maxSize: 1600
        });
        response.then((resultFile) => { this.dateBirthForm.pregnancyAttestation = resultFile; });
      }
    }
  }
};
