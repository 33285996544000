import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import VueMatomo from "vue-matomo";

Vue.config.productionTip = false;

let currentUrl = window.location.href;
var matomoSiteId = (currentUrl.includes('primedenaissance.prd.pub.famiwal.be') ||
  currentUrl.includes('primedenaissance.prd.paas.famiwal.be') ||
  currentUrl.includes('primedenaissance.wallonie.be'))
  ? 5 : 15; // 5 = prod, 15 = acc - http://matomo.prd.paas.famiwal.be/ 

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

var primedenaissanceFamiwalCookies = getCookie('Orejime')
if (primedenaissanceFamiwalCookies !== '' && JSON.parse(primedenaissanceFamiwalCookies).statisticsCookies === true) {
  Vue.use(VueMatomo, {
    host: "https://matomo.prd.pub.famiwal.be/",
    siteId: matomoSiteId,
    trackerFileName: 'matomo',
    router: router,
    // default values below
    //enableLinkTracking: true,
    //requireConsent: false,
    //trackInitialView: true,
    //disableCookies: false,
    //requireCookieConsent: false,
    //enableHeartBeatTimer: false,
    //heartBeatTimerInterval: 15,
    //debug: false,
    //userId: undefined,
    //cookieDomain: undefined,
    //domains: undefined,
    //preInitActions: [],
    //trackSiteSearch: false
  });
}




new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
