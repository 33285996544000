import { validationMixin } from "vuelidate";
import BankAccountEnum from "./../enums/bankAccountEnum.js";
import { helpers, sameAs, requiredIf, required } from "vuelidate/lib/validators";
import belgianIbanFormatterMixin from "../mixins/belgianIbanFormatterMixin.js";

const regex_belge = helpers.regex(
  "regex_belge",
  /[B]{1}[E]{1}[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}/
);
const regex_etranger = helpers.regex(
  "regex_etranger",
  /[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?([a-zA-Z0-9]?){0,16}/
)

export default {
  mixins: [validationMixin, belgianIbanFormatterMixin],
  name: "AccountNumber",
  data() {
    return {
      accountForm: {
        belgianAccount: "",
        foreignAccount: "",
        chk_account: false,
        accountType: BankAccountEnum.BELGIAN,
        rib: null
      },
      BankAccountEnum

    };
  },
  validations() {
    return {
      accountForm: {
        belgianAccount: { required: requiredIf(() => this.accountForm.accountType === BankAccountEnum.BELGIAN), regex_belge },
        foreignAccount: { required: requiredIf(() => this.accountForm.accountType === BankAccountEnum.FOREIGN), regex_etranger },
        chk_account: { required: requiredIf(() => this.accountForm.accountType !== BankAccountEnum.NONE), sameAs: sameAs(() => this.accountForm.accountType !== BankAccountEnum.NONE ? true : false) },
        accountType: { required },
        rib: { required: requiredIf(() => this.accountForm.accountType === BankAccountEnum.FOREIGN) }
      }
    }
  },
  watch: {
    getAccountType(accountType) {
      if (accountType === BankAccountEnum.BELGIAN) {
        this.accountForm.foreignAccount = '';
        this.accountForm.rib = null;
      } else if (accountType === BankAccountEnum.FOREIGN) {
        this.accountForm.belgianAccount = '';
      } else {
        this.accountForm.belgianAccount = '';
        this.accountForm.foreignAccount = '';
        this.accountForm.rib = null;
      }
      this.accountForm.chk_account = false;
    }
  },
  computed: {
    getAccountType() {
      return this.accountForm.accountType;
    }
  },
  methods: {
    validateState(objet) {
      const { $dirty, $error } = this.$v.accountForm[objet];
      return $dirty ? !$error : null;
    },
    format(value) {
      return value.toUpperCase();
    },
    formatBelgianIbanAccount() {
      this.accountForm.belgianAccount = this.formatBelgianIban(this.accountForm.belgianAccount);
    }
  }
};