export default {
  data() {
    return {
      showPreviousPregnancyFullTile: false
    }
  },
  methods: {
    goToWizzard() {
      this.rollbackFooter();
      this.$router.push('/formulaire');
    },
    triggerPregnancyFullTile() {
      this.showPreviousPregnancyFullTile = !this.showPreviousPregnancyFullTile;
    },
    rollbackFooter() {
      var element = document.getElementsByClassName("img")[0];
      element.classList.remove("img-no-footer");
    }
  },
  mounted() {
    var element = document.getElementsByClassName("img")[0];
    element.classList.add("img-no-footer");
  }
};